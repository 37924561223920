import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Quote = props => (
  <Layout bodyClass="page-quote">
    <SEO title="Quote" />
    <br />
    <div className="container">
      <iframe
        src="https://forms.zohopublic.com/admin1965/form/QuoteAppliedContractSourcing/formperma/s0s3CE-JI4pmZJx0mE-pNkMbcTO0_vh-chuTrlwJZcA"
        title="Request a Quote"
        width="100%"
        height="800"
      />
      {/* <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLScwA2vhCoDdHkUboVCfqE0VElLF0sdfuUIu6yYgyTUE56-36w/viewform"
        title="Request a Quote - Google Forms"
        width="100%"
        height="800"
      /> */}
    </div>
    {/* <div className="row">
      <div className="col-8">
        <form
          name="quote"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          data-netlify-recaptcha="true"
          action="success"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="quote" />
          <div className="col-md-8">
            <label htmlFor="name" id="label-name">
              {'Name'}
            </label>
            <br />
            <input type="text" name="name" id="name" required />
            <br />
          </div>
          <div className="col-md-8">
            <label htmlFor="company" id="label-company">
              {'Company'}
            </label>
            <br />
            <input type="text" name="company" id="company" required />
            <br />
          </div>
          <div className="col-md-8">
            <label htmlFor="email" id="label-email">
              {'Email'}
            </label>
            <br />
            <input type="text" name="email" id="email" required />
            <br />
          </div>
          <div className="col-md-8">
            <label htmlFor="message" id="label-message">
              {'Message'}
            </label>
            <br />
            <textarea name="message" id="message" rows="6" />
            <br />
            <br />
          </div>
          <div className="col-md-6">
            <label htmlFor="filepicker" id="label-file">
              <span>
                {
                  'We accept IGES (.igs), AutoCAD (.dwg), STEP (.step, .stp), SolidWorks (.sldprt), Stereolithography (.stl), Parasolid (.x_t, .x_b), Autodesk Inventor (.ipt), .3dxml, CATIA (.catpart), PTC CREO (.prt), .sat, and compressed (.zip) files'
                }
              </span>
            </label>
            <br />
            <input
              type="file"
              id="filepicker"
              accept=".igs,.dwg,.step,.stp,.sldprt,.stl,.x_t,.x_b,.ipt,.3dxml,.catpart,.prt,.sat,.zip"
            />
            <br />
            <br />
          </div>
          <div data-netlify-recaptcha="true" />
          <div className="col-md-2">
            <input type="submit" value="Submit" className="button-submit" />
            <br />
            <br />
          </div>
        </form>
      </div>
    </div> */}
  </Layout>
);

export default Quote;
